/**
 * @constants
 * @description  : Hold CONSTANTS and APIs
 * @Created by   : aesthytik
 */


import axios from 'axios';

export const AXIOS_INSTANCE = axios.create();

AXIOS_INSTANCE.defaults.headers.Accept = 'application/vnd.api+json';

// AXIOS_INSTANCE.defaults.headers["Content-Type"] = "application/vnd.api+json";

AXIOS_INSTANCE.defaults.timeout = 30000;

// All action types

export const LOGIN_CONST = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_RESET: 'LOGIN_RESET',
};

export const GET_PROFILE = {
  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  GET_PROFILE_RESET: 'GET_PROFILE_RESET',
};

export const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  FORGOT_PASSWORD_RESET: 'FORGOT_PASSWORD_RESET',
};

export const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',
};

export const USER_ADD = {
  USER_ADD_REQUEST: 'USER_ADD_REQUEST',
  USER_ADD_SUCCESS: 'USER_ADD_SUCCESS',
  USER_ADD_FAILURE: 'USER_ADD_FAILURE',
  USER_ADD_RESET: 'USER_ADD_RESET',
};

export const YOUTUBE_ADD = {
  YOUTUBE_ADD_REQUEST: 'YOUTUBE_ADD_REQUEST',
  YOUTUBE_ADD_SUCCESS: 'YOUTUBE_ADD_SUCCESS',
  YOUTUBE_ADD_FAILURE: 'YOUTUBE_ADD_FAILURE',
  YOUTUBE_ADD_RESET: 'YOUTUBE_ADD_RESET',
};
export const POST_ADD = {
  POST_ADD_REQUEST: 'POST_ADD_REQUEST',
  POST_ADD_SUCCESS: 'POST_ADD_SUCCESS',
  POST_ADD_FAILURE: 'POST_ADD_FAILURE',
  POST_ADD_RESET: 'POST_ADD_RESET',
};

export const USER_UPDATE = {
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_UPDATE_RESET: 'USER_UPDATE_RESET',
};

export const YOUTUBE_UPDATE = {
  YOUTUBE_UPDATE_REQUEST: 'YOUTUBE_UPDATE_REQUEST',
  YOUTUBE_UPDATE_SUCCESS: 'YOUTUBE_UPDATE_SUCCESS',
  YOUTUBE_UPDATE_FAILURE: 'YOUTUBE_UPDATE_FAILURE',
  YOUTUBE_UPDATE_RESET: 'YOUTUBE_UPDATE_RESET',
};

export const YOUTUBE_ARCHIVE = {
  YOUTUBE_ARCHIVE_REQUEST: 'YOUTUBE_ARCHIVE_REQUEST',
  YOUTUBE_ARCHIVE_SUCCESS: 'YOUTUBE_ARCHIVE_SUCCESS',
  YOUTUBE_ARCHIVE_FAILURE: 'YOUTUBE_ARCHIVE_FAILURE',
  YOUTUBE_ARCHIVE_RESET: 'YOUTUBE_ARCHIVE_RESET',
};

export const USER_POST = {
  USER_POST_REQUEST: 'USER_POST_REQUEST',
  USER_POST_SUCCESS: 'USER_POST_SUCCESS',
  USER_POST_FAILURE: 'USER_POST_FAILURE',
  USER_POST_RESET: 'USER_POST_RESET',
};

export const GET_USERS = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  GET_USERS_RESET: 'GET_USERS_RESET',
};

export const GET_YOUTUBE = {
  GET_YOUTUBE_REQUEST: 'GET_YOUTUBE_REQUEST',
  GET_YOUTUBE_SUCCESS: 'GET_YOUTUBE_SUCCESS',
  GET_YOUTUBE_FAILURE: 'GET_YOUTUBE_FAILURE',
  GET_YOUTUBE_RESET: 'GET_YOUTUBE_RESET',
};

export const GET_POSTS = {
  GET_POSTS_REQUEST: 'GET_POSTS_REQUEST',
  GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
  GET_POSTS_FAILURE: 'GET_POSTS_FAILURE',
  GET_POSTS_RESET: 'GET_POSTS_RESET',
};

export const GET_SINGLE_USER = {
  GET_SINGLE_USER_REQUEST: 'GET_SINGLE_USER_REQUEST',
  GET_SINGLE_USER_SUCCESS: 'GET_SINGLE_USER_SUCCESS',
  GET_SINGLE_USER_FAILURE: 'GET_SINGLE_USER_FAILURE',
  GET_SINGLE_USER_RESET: 'GET_SINGLE_USER_RESET',
};

export const DELETE_SINGLE_USER = {
  DELETE_SINGLE_USER_REQUEST: 'DELETE_SINGLE_USER_REQUEST',
  DELETE_SINGLE_USER_SUCCESS: 'DELETE_SINGLE_USER_SUCCESS',
  DELETE_SINGLE_USER_FAILURE: 'DELETE_SINGLE_USER_FAILURE',
  DELETE_SINGLE_USER_RESET: 'DELETE_SINGLE_USER_RESET',
};
export const DELETE_SINGLE_POST = {
  DELETE_SINGLE_POST_REQUEST: 'DELETE_SINGLE_POST_REQUEST',
  DELETE_SINGLE_POST_SUCCESS: 'DELETE_SINGLE_POST_SUCCESS',
  DELETE_SINGLE_POST_FAILURE: 'DELETE_SINGLE_POST_FAILURE',
  DELETE_SINGLE_POST_RESET: 'DELETE_SINGLE_POST_RESET',
};

export const DELETE_POST_USER = {
  DELETE_POST_USER_REQUEST: 'DELETE_POST_USER_REQUEST',
  DELETE_POST_USER_SUCCESS: 'DELETE_POST_USER_SUCCESS',
  DELETE_POST_USER_FAILURE: 'DELETE_POST_USER_FAILURE',
  DELETE_POST_USER_RESET: 'DELETE_POST_USER_RESET',
};

export const DELETE_SINGLE_YOUTUBE = {
  DELETE_SINGLE_YOUTUBE_REQUEST: 'DELETE_SINGLE_YOUTUBE_REQUEST',
  DELETE_SINGLE_YOUTUBE_SUCCESS: 'DELETE_SINGLE_YOUTUBE_SUCCESS',
  DELETE_SINGLE_YOUTUBE_FAILURE: 'DELETE_SINGLE_YOUTUBE_FAILURE',
  DELETE_SINGLE_YOUTUBE_RESET: 'DELETE_SINGLE_YOUTUBE_RESET',
};

export const CATEGORY_ADD = {
  CATEGORY_ADD_REQUEST: 'CATEGORY_ADD_REQUEST',
  CATEGORY_ADD_SUCCESS: 'CATEGORY_ADD_SUCCESS',
  CATEGORY_ADD_FAILURE: 'CATEGORY_ADD_FAILURE',
  CATEGORY_ADD_RESET: 'CATEGORY_ADD_RESET',
};

export const ADVERTISE_ADD = {
  ADVERTISE_ADD_REQUEST: 'ADVERTISE_ADD_REQUEST',
  ADVERTISE_ADD_SUCCESS: 'ADVERTISE_ADD_SUCCESS',
  ADVERTISE_ADD_FAILURE: 'ADVERTISE_ADD_FAILURE',
  ADVERTISE_ADD_RESET: 'ADVERTISE_ADD_RESET',
};

export const CATEGORY_UPDATE = {
  CATEGORY_UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',
  CATEGORY_UPDATE_RESET: 'CATEGORY_UPDATE_RESET',
};

export const ADVERTISE_UPDATE = {
  ADVERTISE_UPDATE_REQUEST: 'ADVERTISE_UPDATE_REQUEST',
  ADVERTISE_UPDATE_SUCCESS: 'ADVERTISE_UPDATE_SUCCESS',
  ADVERTISE_UPDATE_FAILURE: 'ADVERTISE_UPDATE_FAILURE',
  ADVERTISE_UPDATE_RESET: 'ADVERTISE_UPDATE_RESET',
};

export const GET_CATEGORIES = {
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',
  GET_CATEGORIES_RESET: 'GET_CATEGORIES_RESET',
};

export const GET_ADVERTISE = {
  GET_ADVERTISE_REQUEST: 'GET_ADVERTISE_REQUEST',
  GET_ADVERTISE_SUCCESS: 'GET_ADVERTISE_SUCCESS',
  GET_ADVERTISE_FAILURE: 'GET_ADVERTISE_FAILURE',
  GET_ADVERTISE_RESET: 'GET_ADVERTISE_RESET',
};

export const GET_SINGLE_CATEGORY = {
  GET_SINGLE_CATEGORY_REQUEST: 'GET_SINGLE_CATEGORY_REQUEST',
  GET_SINGLE_CATEGORY_SUCCESS: 'GET_SINGLE_CATEGORY_SUCCESS',
  GET_SINGLE_CATEGORY_FAILURE: 'GET_SINGLE_CATEGORY_FAILURE',
  GET_SINGLE_CATEGORY_RESET: 'GET_SINGLE_CATEGORY_RESET',
};


export const GET_SINGLE_ADVERTISE = {
  GET_SINGLE_ADVERTISE_REQUEST: 'GET_SINGLE_ADVERTISE_REQUEST',
  GET_SINGLE_ADVERTISE_SUCCESS: 'GET_SINGLE_ADVERTISE_SUCCESS',
  GET_SINGLE_ADVERTISE_FAILURE: 'GET_SINGLE_ADVERTISE_FAILURE',
  GET_SINGLE_ADVERTISE_RESET: 'GET_SINGLE_ADVERTISE_RESET',
};

export const GET_SINGLE_YOUTUBE = {
  GET_SINGLE_YOUTUBE_REQUEST: 'GET_SINGLE_YOUTUBE_REQUEST',
  GET_SINGLE_YOUTUBE_SUCCESS: 'GET_SINGLE_YOUTUBE_SUCCESS',
  GET_SINGLE_YOUTUBE_FAILURE: 'GET_SINGLE_YOUTUBE_FAILURE',
  GET_SINGLE_YOUTUBE_RESET: 'GET_SINGLE_YOUTUBE_RESET',
};

export const GET_CATEGORY_BY_TYPE = {
  GET_CATEGORY_BY_TYPE_REQUEST: 'GET_CATEGORY_BY_TYPE_REQUEST',
  GET_CATEGORY_BY_TYPE_SUCCESS: 'GET_CATEGORY_BY_TYPE_SUCCESS',
  GET_CATEGORY_BY_TYPE_FAILURE: 'GET_CATEGORY_BY_TYPE_FAILURE',
  GET_CATEGORY_BY_TYPE_RESET: 'GET_CATEGORY_BY_TYPE_RESET',
};

export const GET_ADVERTISE_BY_TYPE = {
  GET_ADVERTISE_BY_TYPE_REQUEST: 'GET_ADVERTISE_BY_TYPE_REQUEST',
  GET_ADVERTISE_BY_TYPE_SUCCESS: 'GET_ADVERTISE_BY_TYPE_SUCCESS',
  GET_ADVERTISE_BY_TYPE_FAILURE: 'GET_ADVERTISE_BY_TYPE_FAILURE',
  GET_ADVERTISE_BY_TYPE_RESET: 'GET_ADVERTISE_BY_TYPE_RESET',
};

export const GET_YOUTUBE_BY_TYPE = {
  GET_YOUTUBE_BY_TYPE_REQUEST: 'GET_YOUTUBE_BY_TYPE_REQUEST',
  GET_YOUTUBE_BY_TYPE_SUCCESS: 'GET_YOUTUBE_BY_TYPE_SUCCESS',
  GET_YOUTUBE_BY_TYPE_FAILURE: 'GET_YOUTUBE_BY_TYPE_FAILURE',
  GET_YOUTUBE_BY_TYPE_RESET: 'GET_YOUTUBE_BY_TYPE_RESET',
};

export const DELETE_SINGLE_CATEGORY = {
  DELETE_SINGLE_CATEGORY_REQUEST: 'DELETE_SINGLE_CATEGORY_REQUEST',
  DELETE_SINGLE_CATEGORY_SUCCESS: 'DELETE_SINGLE_CATEGORY_SUCCESS',
  DELETE_SINGLE_CATEGORY_FAILURE: 'DELETE_SINGLE_CATEGORY_FAILURE',
  DELETE_SINGLE_CATEGORY_RESET: 'DELETE_SINGLE_CATEGORY_RESET',
};

export const DELETE_SINGLE_ADVERTISE = {
  DELETE_SINGLE_ADVERTISE_REQUEST: 'DELETE_SINGLE_ADVERTISE_REQUEST',
  DELETE_SINGLE_ADVERTISE_SUCCESS: 'DELETE_SINGLE_ADVERTISE_SUCCESS',
  DELETE_SINGLE_ADVERTISE_FAILURE: 'DELETE_SINGLE_ADVERTISE_FAILURE',
  DELETE_SINGLE_ADVERTISE_RESET: 'DELETE_SINGLE_ADVERTISE_RESET',
};

export const QUESTION_ADD = {
  QUESTION_ADD_REQUEST: 'QUESTION_ADD_REQUEST',
  QUESTION_ADD_SUCCESS: 'QUESTION_ADD_SUCCESS',
  QUESTION_ADD_FAILURE: 'QUESTION_ADD_FAILURE',
  QUESTION_ADD_RESET: 'QUESTION_ADD_RESET',
};

export const QUESTION_UPDATE = {
  QUESTION_UPDATE_REQUEST: 'QUESTION_UPDATE_REQUEST',
  QUESTION_UPDATE_SUCCESS: 'QUESTION_UPDATE_SUCCESS',
  QUESTION_UPDATE_FAILURE: 'QUESTION_UPDATE_FAILURE',
  QUESTION_UPDATE_RESET: 'QUESTION_UPDATE_RESET',
};

export const GET_QUESTIONS = {
  GET_QUESTIONS_REQUEST: 'GET_QUESTIONS_REQUEST',
  GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAILURE: 'GET_QUESTIONS_FAILURE',
  GET_QUESTIONS_RESET: 'GET_QUESTIONS_RESET',
};

export const GET_SINGLE_QUESTION = {
  GET_SINGLE_QUESTION_REQUEST: 'GET_SINGLE_QUESTION_REQUEST',
  GET_SINGLE_QUESTION_SUCCESS: 'GET_SINGLE_QUESTION_SUCCESS',
  GET_SINGLE_QUESTION_FAILURE: 'GET_SINGLE_QUESTION_FAILURE',
  GET_SINGLE_QUESTION_RESET: 'GET_SINGLE_QUESTION_RESET',
};

export const DELETE_SINGLE_QUESTION = {
  DELETE_SINGLE_QUESTION_REQUEST: 'DELETE_SINGLE_QUESTION_REQUEST',
  DELETE_SINGLE_QUESTION_SUCCESS: 'DELETE_SINGLE_QUESTION_SUCCESS',
  DELETE_SINGLE_QUESTION_FAILURE: 'DELETE_SINGLE_QUESTION_FAILURE',
  DELETE_SINGLE_QUESTION_RESET: 'DELETE_SINGLE_QUESTION_RESET',
};

export const BLOG_ADD = {
  BLOG_ADD_REQUEST: 'BLOG_ADD_REQUEST',
  BLOG_ADD_SUCCESS: 'BLOG_ADD_SUCCESS',
  BLOG_ADD_FAILURE: 'BLOG_ADD_FAILURE',
  BLOG_ADD_RESET: 'BLOG_ADD_RESET',
};

export const BLOG_UPDATE = {
  BLOG_UPDATE_REQUEST: 'BLOG_UPDATE_REQUEST',
  BLOG_UPDATE_SUCCESS: 'BLOG_UPDATE_SUCCESS',
  BLOG_UPDATE_FAILURE: 'BLOG_UPDATE_FAILURE',
  BLOG_UPDATE_RESET: 'BLOG_UPDATE_RESET',
};

export const GET_BLOGS = {
  GET_BLOGS_REQUEST: 'GET_QUESTIONS_REQUEST',
  GET_BLOGS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
  GET_BLOGS_FAILURE: 'GET_QUESTIONS_FAILURE',
  GET_BLOGS_RESET: 'GET_QUESTIONS_RESET',
};

export const GET_SINGLE_BLOG = {
  GET_SINGLE_BLOG_REQUEST: 'GET_SINGLE_BLOG_REQUEST',
  GET_SINGLE_BLOG_SUCCESS: 'GET_SINGLE_BLOG_SUCCESS',
  GET_SINGLE_BLOG_FAILURE: 'GET_SINGLE_BLOG_FAILURE',
  GET_SINGLE_BLOG_RESET: 'GET_SINGLE_BLOG_RESET',
};

export const DELETE_SINGLE_BLOG = {
  DELETE_SINGLE_BLOG_REQUEST: 'DELETE_SINGLE_BLOG_REQUEST',
  DELETE_SINGLE_BLOG_SUCCESS: 'DELETE_SINGLE_BLOG_SUCCESS',
  DELETE_SINGLE_BLOG_FAILURE: 'DELETE_SINGLE_BLOG_FAILURE',
  DELETE_SINGLE_BLOG_RESET: 'DELETE_SINGLE_BLOG_RESET',
};

export const SKILLS_ADD = {
  SKILLS_ADD_REQUEST: 'SKILLS_ADD_REQUEST',
  SKILLS_ADD_SUCCESS: 'SKILLS_ADD_SUCCESS',
  SKILLS_ADD_FAILURE: 'SKILLS_ADD_FAILURE',
  SKILLS_ADD_RESET: 'SKILLS_ADD_RESET',
};

export const SKILLS_UPDATE = {
  SKILLS_UPDATE_REQUEST: 'SKILLS_UPDATE_REQUEST',
  SKILLS_UPDATE_SUCCESS: 'SKILLS_UPDATE_SUCCESS',
  SKILLS_UPDATE_FAILURE: 'SKILLS_UPDATE_FAILURE',
  SKILLS_UPDATE_RESET: 'SKILLS_UPDATE_RESET',
};

export const GET_SKILLS = {
  GET_SKILLS_REQUEST: 'GET_SKILLS_REQUEST',
  GET_SKILLS_SUCCESS: 'GET_SKILLS_SUCCESS',
  GET_SKILLS_FAILURE: 'GET_SKILLS_FAILURE',
  GET_SKILLS_RESET: 'GET_SKILLS_RESET',
};

export const GET_SINGLE_SKILL = {
  GET_SINGLE_SKILL_REQUEST: 'GET_SINGLE_SKILL_REQUEST',
  GET_SINGLE_SKILL_SUCCESS: 'GET_SINGLE_SKILL_SUCCESS',
  GET_SINGLE_SKILL_FAILURE: 'GET_SINGLE_SKILL_FAILURE',
  GET_SINGLE_SKILL_RESET: 'GET_SINGLE_SKILL_RESET',
};

export const DELETE_SINGLE_SKILL = {
  DELETE_SINGLE_SKILL_REQUEST: 'DELETE_SINGLE_SKILL_REQUEST',
  DELETE_SINGLE_SKILL_SUCCESS: 'DELETE_SINGLE_SKILL_SUCCESS',
  DELETE_SINGLE_SKILL_FAILURE: 'DELETE_SINGLE_SKILL_FAILURE',
  DELETE_SINGLE_SKILL_RESET: 'DELETE_SINGLE_SKILL_RESET',
};

export const BLOG_IMAGE_UPLOAD = {
  BLOG_IMAGE_UPLOAD_REQUEST: 'BLOG_IMAGE_UPLOAD_REQUEST',
  BLOG_IMAGE_UPLOAD_SUCCESS: 'BLOG_IMAGE_UPLOAD_SUCCESS',
  BLOG_IMAGE_UPLOAD_FAILURE: 'BLOG_IMAGE_UPLOAD_FAILURE',
  BLOG_IMAGE_UPLOAD_RESET: 'BLOG_IMAGE_UPLOAD_RESET',
};

export const CHANGE_STATUS = {
  CHANGE_STATUS_REQUEST: 'CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_SUCCESS: 'CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILURE: 'CHANGE_STATUS_FAILURE',
  CHANGE_STATUS_RESET: 'CHANGE_STATUS_RESET',
};

export const TOTAL_COUNT = {
  TOTAL_COUNT_REQUEST: 'TOTAL_COUNT_REQUEST',
  TOTAL_COUNT_SUCCESS: 'TOTAL_COUNT_SUCCESS',
  TOTAL_COUNT_FAILURE: 'TOTAL_COUNT_FAILURE',
  TOTAL_COUNT_RESET: 'TOTAL_COUNT_RESET',
};

export const USER_COUNT = {
  USER_COUNT_REQUEST: 'USER_COUNT_REQUEST',
  USER_COUNT_SUCCESS: 'USER_COUNT_SUCCESS',
  USER_COUNT_FAILURE: 'USER_COUNT_FAILURE',
  USER_COUNT_RESET: 'USER_COUNT_RESET',
};

export const POST_COUNT = {
  POST_COUNT_REQUEST: 'POST_COUNT_REQUEST',
  POST_COUNT_SUCCESS: 'POST_COUNT_SUCCESS',
  POST_COUNT_FAILURE: 'POST_COUNT_FAILURE',
  POST_COUNT_RESET: 'POST_COUNT_RESET',
};

export const NAME_ADD = {
  NAME_ADD_REQUEST: 'NAME_ADD_REQUEST',
  NAME_ADD_SUCCESS: 'NAME_ADD_SUCCESS',
  NAME_ADD_FAILURE: 'NAME_ADD_FAILURE',
  NAME_ADD_RESET: 'NAME_ADD_RESET',
};

export const NAME_UPDATE = {
  NAME_UPDATE_REQUEST: 'NAME_UPDATE_REQUEST',
  NAME_UPDATE_SUCCESS: 'NAME_UPDATE_SUCCESS',
  NAME_UPDATE_FAILURE: 'NAME_UPDATE_FAILURE',
  NAME_UPDATE_RESET: 'NAME_UPDATE_RESET',
};

export const GET_NAMES = {
  GET_NAMES_REQUEST: 'GET_NAMES_REQUEST',
  GET_NAMES_SUCCESS: 'GET_NAMES_SUCCESS',
  GET_NAMES_FAILURE: 'GET_NAMES_FAILURE',
  GET_NAMES_RESET: 'GET_NAMES_RESET',
};

export const GET_SINGLE_NAME = {
  GET_SINGLE_NAME_REQUEST: 'GET_SINGLE_NAME_REQUEST',
  GET_SINGLE_NAME_SUCCESS: 'GET_SINGLE_NAME_SUCCESS',
  GET_SINGLE_NAME_FAILURE: 'GET_SINGLE_NAME_FAILURE',
  GET_SINGLE_NAME_RESET: 'GET_SINGLE_NAME_RESET',
};

export const DELETE_SINGLE_NAME = {
  DELETE_SINGLE_NAME_REQUEST: 'DELETE_SINGLE_NAME_REQUEST',
  DELETE_SINGLE_NAME_SUCCESS: 'DELETE_SINGLE_NAME_SUCCESS',
  DELETE_SINGLE_NAME_FAILURE: 'DELETE_SINGLE_NAME_FAILURE',
  DELETE_SINGLE_NAME_RESET: 'DELETE_SINGLE_NAME_RESET',
};

export const TYPE_ADD = {
  TYPE_ADD_REQUEST: 'TYPE_ADD_REQUEST',
  TYPE_ADD_SUCCESS: 'TYPE_ADD_SUCCESS',
  TYPE_ADD_FAILURE: 'TYPE_ADD_FAILURE',
  TYPE_ADD_RESET: 'TYPE_ADD_RESET',
};

export const TYPE_UPDATE = {
  TYPE_UPDATE_REQUEST: 'TYPE_UPDATE_REQUEST',
  TYPE_UPDATE_SUCCESS: 'TYPE_UPDATE_SUCCESS',
  TYPE_UPDATE_FAILURE: 'TYPE_UPDATE_FAILURE',
  TYPE_UPDATE_RESET: 'TYPE_UPDATE_RESET',
};

export const GET_TYPES = {
  GET_TYPES_REQUEST: 'GET_TYPES_REQUEST',
  GET_TYPES_SUCCESS: 'GET_TYPES_SUCCESS',
  GET_TYPES_FAILURE: 'GET_TYPES_FAILURE',
  GET_TYPES_RESET: 'GET_TYPES_RESET',
};

export const GET_SINGLE_TYPE = {
  GET_SINGLE_TYPE_REQUEST: 'GET_SINGLE_TYPE_REQUEST',
  GET_SINGLE_TYPE_SUCCESS: 'GET_SINGLE_TYPE_SUCCESS',
  GET_SINGLE_TYPE_FAILURE: 'GET_SINGLE_TYPE_FAILURE',
  GET_SINGLE_TYPE_RESET: 'GET_SINGLE_TYPE_RESET',
};

export const DELETE_SINGLE_TYPE = {
  DELETE_SINGLE_TYPE_REQUEST: 'DELETE_SINGLE_TYPE_REQUEST',
  DELETE_SINGLE_TYPE_SUCCESS: 'DELETE_SINGLE_TYPE_SUCCESS',
  DELETE_SINGLE_TYPE_FAILURE: 'DELETE_SINGLE_TYPE_FAILURE',
  DELETE_SINGLE_TYPE_RESET: 'DELETE_SINGLE_TYPE_RESET',
};

export const PACKAGE_ADD = {
  PACKAGE_ADD_REQUEST: 'PACKAGE_ADD_REQUEST',
  PACKAGE_ADD_SUCCESS: 'PACKAGE_ADD_SUCCESS',
  PACKAGE_ADD_FAILURE: 'PACKAGE_ADD_FAILURE',
  PACKAGE_ADD_RESET: 'PACKAGE_ADD_RESET',
};

export const PACKAGE_UPDATE = {
  PACKAGE_UPDATE_REQUEST: 'PACKAGE_UPDATE_REQUEST',
  PACKAGE_UPDATE_SUCCESS: 'PACKAGE_UPDATE_SUCCESS',
  PACKAGE_UPDATE_FAILURE: 'PACKAGE_UPDATE_FAILURE',
  PACKAGE_UPDATE_RESET: 'PACKAGE_UPDATE_RESET',
};

export const GET_PACKAGES = {
  GET_PACKAGES_REQUEST: 'GET_PACKAGES_REQUEST',
  GET_PACKAGES_SUCCESS: 'GET_PACKAGES_SUCCESS',
  GET_PACKAGES_FAILURE: 'GET_PACKAGES_FAILURE',
  GET_PACKAGES_RESET: 'GET_PACKAGES_RESET',
};

export const GET_SINGLE_PACKAGE = {
  GET_SINGLE_PACKAGE_REQUEST: 'GET_SINGLE_PACKAGE_REQUEST',
  GET_SINGLE_PACKAGE_SUCCESS: 'GET_SINGLE_PACKAGE_SUCCESS',
  GET_SINGLE_PACKAGE_FAILURE: 'GET_SINGLE_PACKAGE_FAILURE',
  GET_SINGLE_PACKAGE_RESET: 'GET_SINGLE_PACKAGE_RESET',
};

export const DELETE_SINGLE_PACKAGE = {
  DELETE_SINGLE_PACKAGE_REQUEST: 'DELETE_SINGLE_PACKAGE_REQUEST',
  DELETE_SINGLE_PACKAGE_SUCCESS: 'DELETE_SINGLE_PACKAGE_SUCCESS',
  DELETE_SINGLE_PACKAGE_FAILURE: 'DELETE_SINGLE_PACKAGE_FAILURE',
  DELETE_SINGLE_PACKAGE_RESET: 'DELETE_SINGLE_PACKAGE_RESET',
};

export const PERMISSION_ADD = {
  PERMISSION_ADD_REQUEST: 'PERMISSION_ADD_REQUEST',
  PERMISSION_ADD_SUCCESS: 'PERMISSION_ADD_SUCCESS',
  PERMISSION_ADD_FAILURE: 'PERMISSION_ADD_FAILURE',
  PERMISSION_ADD_RESET: 'PERMISSION_ADD_RESET',
};

export const PERMISSION_UPDATE = {
  PERMISSION_UPDATE_REQUEST: 'PERMISSION_UPDATE_REQUEST',
  PERMISSION_UPDATE_SUCCESS: 'PERMISSION_UPDATE_SUCCESS',
  PERMISSION_UPDATE_FAILURE: 'PERMISSION_UPDATE_FAILURE',
  PERMISSION_UPDATE_RESET: 'PERMISSION_UPDATE_RESET',
};

export const GET_PERMISSIONS = {
  GET_PERMISSIONS_REQUEST: 'GET_PACKAGES_REQUEST',
  GET_PERMISSIONS_SUCCESS: 'GET_PACKAGES_SUCCESS',
  GET_PERMISSIONS_FAILURE: 'GET_PACKAGES_FAILURE',
  GET_PERMISSIONS_RESET: 'GET_PACKAGES_RESET',
};

export const GET_SINGLE_PERMISSION = {
  GET_SINGLE_PERMISSION_REQUEST: 'GET_SINGLE_PERMISSION_REQUEST',
  GET_SINGLE_PERMISSION_SUCCESS: 'GET_SINGLE_PERMISSION_SUCCESS',
  GET_SINGLE_PERMISSION_FAILURE: 'GET_SINGLE_PERMISSION_FAILURE',
  GET_SINGLE_PERMISSION_RESET: 'GET_SINGLE_PERMISSION_RESET',
};

export const DELETE_SINGLE_PERMISSION = {
  DELETE_SINGLE_PERMISSION_REQUEST: 'DELETE_SINGLE_PERMISSION_REQUEST',
  DELETE_SINGLE_PERMISSION_SUCCESS: 'DELETE_SINGLE_PERMISSION_SUCCESS',
  DELETE_SINGLE_PERMISSION_FAILURE: 'DELETE_SINGLE_PERMISSION_FAILURE',
  DELETE_SINGLE_PERMISSION_RESET: 'DELETE_SINGLE_PERMISSION_RESET',
};

export const GET_USERS_FOR_INVITE = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const GET_POST_FOR_INVITE = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};



export const AUTHENTICATE = 'AUTHENTICATE';

export const DEAUTHENTICATE = 'DEAUTHENTICATE';

// export const API_SLUG = 'http://34.235.127.120:4602';
// export const API_SLUG = 'http://198.251.65.146:4502';

// export const API_SLUG = 'http://localhost:1337';
// export const API_SLUG = 'http://198.251.65.146:4502';
export const API_SLUG = 'https://endpoint.crowdsavetheworld.com';

// export const API_SLUG = 'http://3.18.4.95:1339';

// all api end points

export const LOGIN_API = `${API_SLUG}/oauth/token`;
export const FORGOT_PASSWORD_API = `${API_SLUG}/forgotpassword`;
export const CHANGE_PASSWORD_API = `${API_SLUG}/oauth/token`;
export const USER_API = `${API_SLUG}/user`;
export const YOUTUBE_API = `${API_SLUG}/youTubeLink`;
export const POST_API = `${API_SLUG}/allposts`;
export const ALL_CATEGORY_API = `${API_SLUG}/allcategory`;
export const ALL_ADVERTISE_API = `${API_SLUG}/getAllAdvertisement`;
export const ALL_YOUTUBE_API = `${API_SLUG}/allyouTubeLink`
export const CATEGORY_API = `${API_SLUG}/category`;
export const ADVERTISE_API = `${API_SLUG}/advertisement`;
export const ALL_QUESTION_API = `${API_SLUG}/questionsadmin`;
export const ADD_QUESTION_API = `${API_SLUG}/addquestion`;
export const QUESTION_API = `${API_SLUG}/question`;
export const DELETE_QUESTION_API = `${API_SLUG}/delete`;
export const ALL_BLOGS_API = `${API_SLUG}/blogs`;
export const BLOG_API = `${API_SLUG}/blogs`;
export const DELETE_API = `${API_SLUG}/delete`;
export const IMAGE_UPLOAD_API = `${API_SLUG}/upload`;
export const CHANGE_STATUS_API = `${API_SLUG}/changestatus`;
export const TOTAL_COUNT_API = `${API_SLUG}/totalcount`;
export const USER_COUNT_API = `${API_SLUG}/usercount`;
export const SKILLS_API = `${API_SLUG}/allskills`;
export const PROJECT_API = `${API_SLUG}/project`;
export const SUBSCRIPTION_NAME_API = `${API_SLUG}/subscribename`;
export const ALL_SUBSCRIPTION_NAME_API = `${API_SLUG}/subscribenameall`;
export const SUBSCRIPTION_TYPE_API = `${API_SLUG}/subscribetype`;
export const ALL_SUBSCRIPTION_TYPE_API = `${API_SLUG}/subscribetypeall`;
export const USER_DETAIL_API = `${API_SLUG}/getuserdetail`;
export const SUBSCRIBE_PACKAGE_API = `${API_SLUG}/subscribepackage`;
export const DELETE_PACKAGE_API = `${API_SLUG}/delete`;
export const PERMISSION_API = `${API_SLUG}/permission`;
export const TYPES_API = `${API_SLUG}/type`;
export const SINGLE_TYPE_API = `${API_SLUG}/singletype`;
export const CATEGORY_BY_TYPE_API = `${API_SLUG}/parentcat`;
export const ADVERTISE_BY_TYPE_API = `${API_SLUG}/parentcat`;
export const YOUTUBE_BY_TYPE_API = `${API_SLUG}/parentcat`;
export const GET_USERS_LIST_API = `${API_SLUG}/usersForSelect`;
export const SEND_USERS_INVITATION_API = `${API_SLUG}/sendInvite`;
export const ALL_POST = `${API_SLUG}/admin`;
export const DELETE_POST = `${API_SLUG}/delete`;


export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua &amp; Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia &amp; Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre &amp; Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts &amp; Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad &amp; Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks &amp; Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
