import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import UserListItem from './UserListItem';
import Pagination from '../global/Pagination';
import EmptyState from '../global/EmptyState';
import Loading from '../global/Loader';
import Posts from '../Posts/Posts';

const UserListing = ({
  handleFormVisibilty,
  handAddFormToggle,
  getSearchKeyword,
  getUserId,
  resetSingleUser,
  changeStatus,
  deleteUser,
  users,
  sort,
  setSort,
  total,
  setPage,
  getStatus,
  toggleSort,
  page,
  count,
  getAllUser,
  resetStatus,
  isRequesting
}) => {
  const [keyword, setKeyword] = useState('');
  useEffect(() => {
    getSearchKeyword(keyword);
  }, [getSearchKeyword, keyword]);
  console.log('users', total);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleFormVisibilty();
                  handAddFormToggle(true);
                  // resetSingleCategory();
                }}
                type="button"
              >
                Add User
              </button>
            </h4>
            <div className="card-header-form">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={e => {
                      setKeyword(e.target.value);
                      setPage(1);
                    }}
                  />
                  <div className="input-group-btn">
                    <button className="btn btn-primary">
                      <i className="fas fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {isRequesting ? (
            <Loading />
          ) : (
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tr>
                    <th>#</th>
                    <th
                      onClick={() => toggleSort('fullName')}
                      style={{ cursor: 'pointer' }}
                    >
                      Full Name{' '}
                      <i className={`fas fa-chevron-${sort ? 'down' : 'up'}`} />
                    </th>
                    <th
                      onClick={() => toggleSort('username')}
                      style={{ cursor: 'pointer' }}
                    >
                      Email{' '}
                      <i className={`fas fa-chevron-${sort ? 'down' : 'up'}`} />
                    </th>
                    <th>Mobile</th>
                    <th>Status</th>
                    <th>Guide</th>
                    <th>Action</th>
                  </tr>
                  {users &&
                    users.map((item, index) => (
                      console.log(item,"usersmap"),

                      <>
                        <UserListItem
                          key={item.id}
                          item={item}
                          index={index}
                          resetStatus={resetStatus}
                          getAllUser={getAllUser}
                          handAddFormToggle={handAddFormToggle}
                          handleFormVisibilty={handleFormVisibilty}
                          getUserId={getUserId}
                          deleteUser={deleteUser}
                          changeStatus={changeStatus}
                          getStatus={getStatus}
                          page={page}
                          count={count}
                        />
             

                      </>

                    ))}
               
                </table>
                {users && isEmpty(users) && <EmptyState />}
              </div>
            </div>
          )}
          {users && !isEmpty(users) && (
            <Pagination total={total} setPage={setPage} page={page} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserListing;
