import React from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { truncate } from 'lodash';
import Posts from '../Posts/Posts';

const ContentListItem = ({
  item,
  index,
  handAddFormToggle,
  handleFormVisibilty,
  deleteBlog,
  getBlogId,
  changeStatus,
  getStatus,
  page,
  count
}) => {
  const handleDelete = () => {
    const token = localStorage.getItem('token');
    swal({
      title: 'Are you sure?',
      text: 'you want to delete the blog!',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        deleteBlog({ model: 'blogs', id: item && item.id }, token);
      } else {
        return null;
      }
    });
  };

  const handleStatus = status => {
    const token = localStorage.getItem('token');
    const obj = {
      model: 'blogs',
      id: item && item.id,
      status
    };
    getStatus(status);
    changeStatus(obj, token);
  };

  return (
    <>
    <tr>
      <td>{index + page * count - (count - 1)}</td>
      <td>
          {item && item.title ? item.title : '___'}
      </td>
      <td dangerouslySetInnerHTML={{ __html: item && item.meta_description}}></td>
      <td>{item && item.slug ? item.slug : '___'}</td>

      
      <td>
        <button
          type="button"
          className="btn btn-icon btn-primary mr-2"
          onClick={() => {
            handAddFormToggle(false);
            handleFormVisibilty();
            getBlogId(item);
          }}
          disabled={!!(item && item.type === 'custom')}
        >
          <i className="far fa-edit" />
        </button>
        {/* <button
          type="button"
          className="btn btn-icon btn-danger"
          onClick={handleDelete}
          disabled={!!(item && item.type === 'custom')}
        >
          <i className="fas fa-trash" />
        </button> */}
      </td>
    </tr>
    </>
  );
};

export default ContentListItem;
